
export const LanguageOptions = {
    en: 'en',
    es: 'es'
}

export const LanguageOptionsSelectors = {
    en: { label: "English", value: 'en' },
    es: { label: "Español", value: 'es' }
}

export const TranslationConstants = {
    aboutUs: `ABOUT_US`,
    home: `HOME`,
    tryAtHome: 'TRY_AT_HOME',
    homeAboutHeader: `HOME_ABOUT_HEADER`,
    homeAboutLeftContent: `HOME_ABOUT_LEFT_CONTENT`,
    homeAboutRightContent: `HOME_ABOUT_RIGHT_CONTENT`,
    aboutPageLeftContent: `ABOUT_PAGE_LEFT_CONTENT`,
    aboutPageRightContent: `ABOUT_PAGE_RIGHT_CONTENT`,
    contactUs: `CONTACT_US`,
    socialMedia: `SOCIAL_MEDIA`,
    followUs: `FOLLOW_US`,
    details: `DETAILS`,
    ingredients: 'INGREDIENTS',
    nearYou: `NEAR_YOU`,
    find: `FIND`,
    viewMoreProducts: 'VIEW_MORE_PRODUCTS',
    meetTheFamily: `MEET_THE_FAMILY`,
    fiveWays: `FIVE_WAYS`,
    products: `PRODUCTS`,
    recipes: `RECIPES`,
    cookingInstructions: `COOKING_INSTRUCTIONS`,
    readyIn: `READY_IN`,
    serves: `SERVES`,
    ingredients: `INGREDIENTS`,
    directions: `DIRECTIONS:`,
    featuredProduct: `FEATURED_PRODUCT`,
    learnMore: `LEARN_MORE`,
    viewNutritionalFacts: `VIEW_NUTRITIONAL_FACTS`,
    name: `NAME`,
    phone: `PHONE`,
    email: `EMAIL`,
    submit: `SUBMIT`,
    message: `MESSAGE`,
    holsumBread: `HOLSUM_BREAD`
};

export const LanguageTranslations = {
    [LanguageOptions.en]: {
        translation: {
            [TranslationConstants.viewNutritionalFacts]: `View Nutritional Facts`,
            [TranslationConstants.aboutUs]: `About Us`,
            [TranslationConstants.products]: `Products`,
            [TranslationConstants.featuredProduct]: `FEATURED PRODUCT`,
            [TranslationConstants.recipes]: `Recipes`,
            [TranslationConstants.cookingInstructions]: `COOKING INSTRUCTIONS`,
            [TranslationConstants.directions]: `DIRECTIONS`,
            [TranslationConstants.readyIn]: `READY IN:`,
            [TranslationConstants.serves]: `SERVES:`,
            [TranslationConstants.ingredients]: `INGREDIENTS:`,
            [TranslationConstants.home]: `Home`,
            [TranslationConstants.tryAtHome]: `TRY OUT THESE FUN RECIPES AT HOME!`,
            [TranslationConstants.homeAboutHeader]: `Get To Know Us!`,
            [TranslationConstants.contactUs]: `Contact Us`,
            [TranslationConstants.name]: `Name`,
            [TranslationConstants.phone]: `Phone`,
            [TranslationConstants.email]: `Email`,
            [TranslationConstants.message]: `Message`,
            [TranslationConstants.submit]: `Submit`,
            [TranslationConstants.details]: `Details`,
            [TranslationConstants.ingredients]: `Ingredients`,
            [TranslationConstants.nearYou]: `Near You`,
            [TranslationConstants.find]: `Find`,
            [TranslationConstants.holsumBread]: `Holsum Bread`,
            [TranslationConstants.viewMoreProducts]: `View More Products`,
            [TranslationConstants.socialMedia]: `Check us out on social media`,
            [TranslationConstants.followUs]: `Follow us on Facebook, Instagram, and Twitter`,
            [TranslationConstants.meetTheFamily]: `Meet The Holsum Family`,
            [TranslationConstants.fiveWays]: `Five ways we’re keeping it delicious`,
            [TranslationConstants.homeAboutLeftContent]: `In 1958, the first ovens dedicated to Holsum bread on the island were established in Bayamón. What was then a small and enterprising company dedicated to the manufacture of sliced bread has become the base of our economy, with more than 800 employees committed and passionate about their work. Today the more than 80 Holsum products are part of the Puerto Rican tradition. Their rich pound cakes “Ponquesitos”, tasty coffee cakes, sweet donuts, and delicious Bimbo cookies are authentic flavors that symbolize Puerto Rico. Like the unforgettable "Enanito Holsum" that for three decades represented the company. So much was his love and loyalty for Holsum that already in his retirement he continued to wear the blue and orange uniform that characterized him.`,
            [TranslationConstants.homeAboutRightContent]: `Since 1971, Holsum is located in its current plant in Toa Baja, and at the same time, it opened its distribution centers in Ponce and Aguadilla. From these three locations, its sales fleet of 200 emblematic orange trucks are set off to distribute the bread, cookies, and cakes of the Holsum, Bimbo, and Country Hearth brands throughout the island. Through its history Holsum has been distinguished for its innovation, it has an Innovations Committee that evaluates new ideas to meet the needs and tastes of its consumers.The understanding of Puerto Rican taste becomes evident with each successful launch, as seen with the launch of the Country Hearth 12 Grain Bites, and more recently the corn and banana Bimbo Mini Cakes.`,
            [TranslationConstants.aboutPageLeftContent]: `In 1958, the first ovens dedicated to Holsum bread on the island were established in Bayamón. What was then a small and enterprising company dedicated to the manufacture of sliced bread has become the base of our economy, with more than 800 employees committed and passionate about their work. Today the more than 80 Holsum products are part of the Puerto Rican tradition. Their rich pound cakes “Ponquesitos”, tasty coffee cakes, sweet donuts, and delicious Bimbo cookies are authentic flavors that symbolize Puerto Rico. Like the unforgettable "Enanito Holsum" that for three decades represented the company. So much was his love and loyalty for Holsum that already in his retirement he continued to wear the blue and orange uniform that characterized him. Since 1971, Holsum is located in its current plant in Toa Baja, and at the same time, it opened its distribution centers in Ponce and Aguadilla. From these three locations, its sales fleet of 200 emblematic orange trucks are set off to distribute the bread, cookies, and cakes of the Holsum, Bimbo, and Country Hearth brands throughout the island. Through its history Holsum has been distinguished for its innovation, it has an Innovations Committee that evaluates new ideas to meet the needs and tastes of its consumers.The understanding of Puerto Rican taste becomes evident with each successful launch, as seen with the launch of the Country Hearth 12 Grain Bites, and more recently the corn and banana Bimbo Mini Cakes.`,
            [TranslationConstants.aboutPageRightContent]: `Another successful innovation of the company was the creation of the Bimbo Theater, a space where we recieve visitors.Every year Holsum's operations receive certifications that confirm the superior quality that meets industry standards from entities such as the "Army", "Safe Quality Food Institute" and the "American Institute of Baking". The company's record of quality and reliability has not only made it possible for it to supply the entire local market, including school lunch and fast food restaurants, but also to export its products throughout the Caribbean. In 1998, Holsum became a distinctly Puerto Rican company when its president, Mr. Ramón Calderón, acquired it. Holsum is an example of the social commitment of companies that speak well of the island. This fact was demonstrated when just days after Hurricane Maria, a team of volunteer employees, wanting to contribute in a small way, prepared spread sandwiches, and went out every day to visit seriously affected communities. In less than two months, our ambassadors of hope had distributed blessings to more than 21,000 people, making sure that bread was never missing in any household table. This company, a pioneer in the production of sliced bread in the island, has set standards in the industry for its innovative vision, excellence, quality, economic impact, and commitment to its people. Holsum celebrates its 60 years proud to be Puerto Rico’s bread.`
        }
    },
    [LanguageOptions.es]: {
        translation: {
            [TranslationConstants.viewNutritionalFacts]: `Ver nutricionales`,
            [TranslationConstants.aboutUs]: `¿Quiénes somos?`,
            [TranslationConstants.tryAtHome]: `¡PRUEBA ESTAS DIVERTIDAS RECETAS EN CASA!`,
            [TranslationConstants.home]: `Inicio`,
            [TranslationConstants.homeAboutHeader]: `¡Conócenos!`,
            [TranslationConstants.contactUs]: `Contáctanos`,
            [TranslationConstants.name]: `Nombre`,
            [TranslationConstants.phone]: `Teléfono`,
            [TranslationConstants.email]: `Correo electrónico`,
            [TranslationConstants.message]: `Mensaje`,
            [TranslationConstants.submit]: `Enviar`,
            [TranslationConstants.details]: `Los Detalles`,
            [TranslationConstants.products]: `Productos`,
            [TranslationConstants.featuredProduct]: `Producto destacado`,
            [TranslationConstants.recipes]: `Recetas`,
            [TranslationConstants.readyIn]: `
            Preparado`,
            [TranslationConstants.learnMore]: `
            Aprende más`,
            [TranslationConstants.cookingInstructions]: `
            Instrucciones de cocina`,
            [TranslationConstants.directions]: `
            Direcciones`,
            [TranslationConstants.serves]: `
            Tamaño de la porción: `,
            [TranslationConstants.ingredients]: `Ingredientes`,
            [TranslationConstants.holsumBread]: `Panes Holsum`,
            [TranslationConstants.nearYou]: `Cerca De Ti`,
            [TranslationConstants.find]: `Encuentre`,
            [TranslationConstants.viewMoreProducts]: `Ver Más Productos`,
            [TranslationConstants.meetTheFamily]: `Conoce la familia de productos Holsum`,
            [TranslationConstants.fiveWays]: `Cinco maneras en que lo estamos manteniendo delicioso`,
            [TranslationConstants.socialMedia]: `Síguenos en redes sociales`,
            [TranslationConstants.followUs]: `Síguenos en Facebook, Instagram y Twitter`,
            [TranslationConstants.homeAboutLeftContent]: `En 1958, los primeros hornos dedicados al pan Holsum en la isla se establecieron en Bayamón, Puerto Rico. Lo que entonces era una empresa pequeña y emprendedora dedicada a la fabricación de pan, se ha convertido en la base de nuestra economía, con más de 800 empleados comprometidos y apasionados por su trabajo. Hoy más de 80 productos Holsum son parte de la tradición puertorriqueña. Sus ricos bizcochos “Ponquesitos”, sabrosos coffee cake, donas dulces, y deliciosas galletas Bimbo son sabores auténticos que simbolizan a Puerto Rico. Como el inolvidable “Enanito Holsum” que por tres décadas representó a la empresa. Tanto era su amor y lealtad por Holsum que ya en su retiro continuó usando el uniforme azul y naranja que lo caracterizaba.`,
            [TranslationConstants.homeAboutRightContent]: `Desde 1971, Holsum fue localizada en su planta vigente en Toa Baja, y al mismo tiempo, abrió sus centros de distribución en Ponce y Aguadilla. Desde estos tres lugares, su flota de ventas de 200 emblemáticos camiones anaranjados salen para distribuir el pan, las galletas y los bizcochos de las marcas Holsum, Bimbo y Country Hearth en toda la isla. A través de su historia, Holsum se ha distinguido por su innovación. Cuenta con un Comité de Innovaciones que evalúa nuevas ideas para satisfacer las necesidades y gustos de sus consumidores. La comprensión del gusto puertorriqueño se hace evidente con cada lanzamiento exitoso, como se ve con el lanzamiento de los Panes de Canela de Rebanada Ancha Original e Integral, y más recientemente los lanzamientos de Pan de Mantequilla, Panecillos Kaiser, Pan de Hamburger Jumbo con Semillas y Pan de Mallorca Holsum.`,
            [TranslationConstants.aboutPageLeftContent]: `En 1958, los primeros hornos dedicados al pan de Holsum en la isla se establecieron en Bayamón, Puerto Rico. Lo que entonces era una empresa pequeña y emprendedora dedicada a la fabricación de pan, se ha convertido en la base de nuestra economía, con más de 800 empleados comprometidos y apasionados por su trabajo. Hoy más de 80 productos Holsum son parte de la tradición puertorriqueña. Sus ricos bizcochos “Ponquesitos”, sabrosos coffee cakes, donas dulces, y deliciosas galletas Bimbo son sabores auténticos que simbolizan a Puerto Rico. Como el inolvidable “Enanito Holsum” que por tres décadas representó a la empresa. Tanto era su amor y lealtad por Holsum que ya en su retiro continuó usando el uniforme azul y naranja que lo caracterizaba. Desde 1971, Holsum fue localizada en su planta vigente en Toa Baja, y al mismo tiempo, abrió sus centros de distribución en Ponce y Aguadilla. Desde estos tres lugares, su flota de ventas de 200 emblemáticos camiones anaranjados salen para distribuir el pan, las galletas y los bizcochos de las marcas Holsum, Bimbo y Country Hearth en toda la isla. A través de su historia, Holsum se ha distinguido por su innovación. Cuenta con un Comité de Innovaciones que evalúa nuevas ideas para satisfacer las necesidades y gustos de sus consumidores. La comprensión del gusto puertorriqueño se hace evidente con cada lanzamiento exitoso, como se ve con el lanzamiento de los Panes de Canela de Rebanada Ancha Original e Integral, y más recientemente los lanzamientos de Pan de Mantequilla, Panecillos Kaiser, Pan de Hamburger Jumbo con Semillas y Pan de Mallorca Holsum.`,
            [TranslationConstants.aboutPageRightContent]: `Otra innovación exitosa de la compañía fue la creación del Teatro Bimbo, un espacio donde recibimos visitantes. Cada año, las operaciones de Holsum reciben certificaciones que confirman la calidad superior que cumple con los estándares de la industria de entidades como el "ARMY" y "Safe Quality Food Institute". El historial de calidad y confiabilidad de la compañía no solo le ha permitido abastecer todo el mercado local, incluidos los restaurantes de comida rápida, sino también exportar sus productos en todo el Caribe. En 1998, Holsum se convirtió en una compañía netamente puertorriqueña cuando su presidente, el Sr. Ramón Calderón, la adquirió. Holsum es un ejemplo del compromiso social de las empresas que hablan bien de la isla. Este hecho se demostró cuando solo unos días después del huracán María, un equipo de empleados voluntarios, que deseaba contribuir de manera pequeña, preparó sandwiches y salió todos los días para visitar comunidades gravemente afectadas. En menos de dos meses, nuestros embajadores de la esperanza distribuyeron bendiciones a más de 21,000 personas, asegurándose de que el pan nunca faltara en la mesa de ningún hogar. Esta compañía, pionera en la producción de pan rebanado en la isla, ha establecido estándares en la industria por su visión innovadora, excelencia, calidad, impacto económico y compromiso con su gente. Holsum celebra sus 60 años orgullosos de ser el pan de Puerto Rico.`
        }
    }
};