import * as Actions from '../constants'
import { LanguageOptions } from '../../Constants'

const initialState = {
    contactOpen: false,
    contestDetailsOpen: false,
    nutritionalFactsOpen: false,
    hoveredCell: null,
    getProductsLoading: false,
    getProductsFailed: false,
    getRecipesLoading: false,
    getRecipesFailed: false,
    products: [],
    recipes: [],
    navOpen: false,
    productImagesLoading: true,
    language: LanguageOptions.es,
    nutritionalFactImage: null
}

const getRecipesFailed = (state, action) => {
    return Object.assign({}, state, {
        getRecipesFailed: true,
        getRecipesLoading: false,
        errorMessage: action.msg
    })
}

const getRecipesLoading = (state, action) => {
    let obj = {}
    obj.getProductsLoading = action.bool
    if (action.bool) {
        obj.getRecipesSuccess = false
        obj.getRecipesFailed = false
    }
    return Object.assign({}, state, {
        ...obj
    })
}

const getRecipesSuccess = (state, action) => {
    return Object.assign({}, state, {
        getRecipesFailed: false,
        getRecipesLoading: false,
        recipes: action.result.data
    })
}

const getProductsFailed = (state, action) => {
    return Object.assign({}, state, {
        getProductsFailed: true,
        getProductsLoading: false,
        errorMessage: action.msg
    })
}

const getProductsLoading = (state, action) => {
    let obj = {}
    obj.getProductsLoading = action.bool
    if (action.bool) {
        obj.getProductsSuccess = false
        obj.getProductsFailed = false
    }
    return Object.assign({}, state, {
        ...obj
    })
}

const getProductsSuccess = (state, action) => {
    const priorityProductNames = ["Pan Club", "Pan Club Integral", "Pan Club Integral Blanco", "Pan de Hamburger", "Pan de Hot Dogs 8 pk"];
    let priorityProducts = action.result.data.filter(p => priorityProductNames.some(n => n === p.name));
    const restOfProducts = action.result.data.filter(p => priorityProducts.every(pp => p.name !== pp.name));
    priorityProducts = priorityProductNames.reduce((f, c, i) => {
        priorityProducts.forEach((cc) => cc.name === c ? f.push(cc) : null);
        return f;
    }, [])

    const orderedProducts = [...priorityProducts, ...restOfProducts];
    return Object.assign({}, state, {
        getProductsFailed: false,
        getProductsLoading: false,
        products: orderedProducts
    })
}

const toggleNav = (state, action) => {
    return Object.assign({}, state, {
        navOpen: action.bool
    })
}


const toggleContact = (state, action) => {
    return Object.assign({}, state, {
        contactOpen: action.bool
    })
}

const toggleContestDetails = (state, action) => {
    return Object.assign({}, state, {
        contestDetailsOpen: action.bool
    })
}

const toggleNutritionalFacts = (state, action) => {
    return Object.assign({}, state, {
        nutritionalFactsOpen: action.bool
    })
}

const mainReducer = (state = initialState, action) => {

    switch (action.type) {
        case Actions.TOGGLE_CONTACT:
            return toggleContact(state, action);
        case Actions.TOGGLE_CONTEST_DETAILS:
            return toggleContestDetails(state, action);
        case Actions.TOGGLE_NUTRITION:
            return toggleNutritionalFacts(state, action);
        case Actions.PRODUCT_IMAGES_LOADING:
            return {
                ...state,
                productImagesLoading: action.bool
            }
        case Actions.GET_PRODUCTS_FAILED:
            return getProductsFailed(state, action)
        case Actions.GET_PRODUCTS_LOADING:
            return getProductsLoading(state, action)
        case Actions.GET_PRODUCTS_SUCCESS:
            return getProductsSuccess(state, action)
        case Actions.GET_RECIPES_FAILED:
            return getRecipesFailed(state, action)
        case Actions.GET_RECIPES_LOADING:
            return getRecipesLoading(state, action)
        case Actions.GET_RECIPES_SUCCESS:
            return getRecipesSuccess(state, action)
        case Actions.UPDATE_VIEWED_LOCATION:
            return Object.assign({}, state, {
                hoveredCell: action.index
            })
        case Actions.SELECT_LANGUAGE:
            return Object.assign({}, state, {
                ...state,
                language: action.language
            })
        case Actions.TOGGLE_NAV:
            return toggleNav(state, action)
        case Actions.SET_NUTRI_FACTS_IMAGE:
            return { ...state, nutritionalFactImage: action.src }
        default:
            return state;
    }
}

export default mainReducer;

