
export const GET_PRODUCTS_LOADING = 'GET_PRODUCTS_LOADING';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_RECIPES_LOADING = 'GET_RECIPES_LOADING';
export const GET_RECIPES_FAILED = 'GET_RECIPES_FAILED';
export const GET_RECIPES_SUCCESS = 'GET_RECIPES_SUCCESS';
export const UPDATE_VIEWED_LOCATION = 'UPDATE_VIEWED_LOCATION';
export const PRODUCT_IMAGES_LOADING = 'PRODUCT_IMAGES_LOADING';
export const TOGGLE_NAV = 'TOGGLE_NAV';
export const TOGGLE_CONTACT = 'TOGGLE_CONTACT';
export const TOGGLE_NUTRITION = 'TOGGLE_NUTRITION';
export const TOGGLE_CONTEST_DETAILS = 'TOGGLE_CONTEST_DETAILS';
export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';
export const SET_NUTRI_FACTS_IMAGE = 'SET_NUTRI_FACTS_IMAGE';