// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-madres-js": () => import("./../../../src/pages/madres.js" /* webpackChunkName: "component---src-pages-madres-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-single-product-js": () => import("./../../../src/pages/single-product.js" /* webpackChunkName: "component---src-pages-single-product-js" */),
  "component---src-pages-single-recipe-js": () => import("./../../../src/pages/single-recipe.js" /* webpackChunkName: "component---src-pages-single-recipe-js" */),
  "component---src-pages-tienditas-js": () => import("./../../../src/pages/tienditas.js" /* webpackChunkName: "component---src-pages-tienditas-js" */)
}

