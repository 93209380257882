import {
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILED,
    GET_PRODUCTS_LOADING,
    GET_RECIPES_FAILED,
    GET_RECIPES_LOADING,
    GET_RECIPES_SUCCESS,
    UPDATE_VIEWED_LOCATION,
    TOGGLE_NAV,
    TOGGLE_CONTACT,
    TOGGLE_CONTEST_DETAILS
} from '../constants'
import axios from 'axios'

export function getProducts() {
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch(getProductsLoading(true))
            axios.get(`${process.env.BASE_URL}/products?orgId=1`).then((response) => {
                dispatch(getProductsSuccess(response))
                resolve(response)
            }).catch(err => dispatch(getProductsFailed(err)))
        })
    }
}

export function getRecipes() {
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch(getRecipesLoading(true))
            axios.get(`${process.env.BASE_URL}/recipes?orgId=1`).then((response) => {
                dispatch(getRecipesSuccess(response))
                resolve(response)
            }).catch(err => dispatch(getRecipesFailed(err)))
        })
    }
}

export function toggleNav(bool) {
    return { type: TOGGLE_NAV, bool }
}

export function toggleContact(bool) {
    return { type: TOGGLE_CONTACT, bool }
}

export function toggleContestDetails(bool) {
    return { type: TOGGLE_CONTEST_DETAILS, bool }
}


export function getProductsLoading(bool) {
    return { type: GET_PRODUCTS_LOADING, bool }
}

export function getProductsSuccess(result) {
    return { type: GET_PRODUCTS_SUCCESS, result }
}

export function getProductsFailed(msg) {
    return { type: GET_PRODUCTS_FAILED, msg }
}

export function getRecipesLoading(bool) {
    return { type: GET_RECIPES_LOADING, bool }
}

export function getRecipesSuccess(result) {
    return { type: GET_RECIPES_SUCCESS, result }
}

export function getRecipesFailed(msg) {
    return { type: GET_RECIPES_FAILED, msg }
}

export function updateLocationCell(index) {
    return { type: UPDATE_VIEWED_LOCATION, index }
}

