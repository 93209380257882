///wrap-with-provider.js


import React from "react"
import { Provider } from "react-redux"
import createStore from "./src/state/createStore"
import { getProducts, getRecipes } from './src/state/actions'

export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const store = createStore()
    var heap = {};
    var waitForWindow = setInterval(() => {
        if (typeof window !== `undefined`) {
            clearInterval(waitForWindow);
            window.heap = window.heap || [];
            heap.load = function (e, t) {
                window.heap.appid = e;
                window.heap.config = t = t || {};

                var r = document.createElement("script");
                r.type = "text/javascript"
                r.async = !0
                r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";

                var a = document.getElementsByTagName("script")[0];
                a.parentNode.insertBefore(r, a);

                for (var n = function (e) {
                    return function () {
                        heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                    }
                },
                    p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"],
                    o = 0;
                    o < p.length; o++)
                    heap[p[o]] = n(p[o])
            };
            process.env.NODE_ENV === "production" && heap.load("2161517089");
        }
    }, 100)

    // Added some 
    store.dispatch(getRecipes())
    store.dispatch(getProducts())

    return <Provider store={store}>{element}</Provider>
}